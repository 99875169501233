<template>
    <div>
        <Dialog v-model:visible='showUserInterview' modal header='Interviste' :style="{ width: '50rem' }"
                :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <template v-for='(value, key) in userInterview' :key='key'>
                <Card>
                    <template #title>Intervista del {{ localeTs2Date(value['submitted_at']) }}</template>
                    <template #content>
                        <template v-for='answer in value["answers"]' :key='"ans_"+key+"_"+answer["answer_id"]'>
                            <b>{{ answer['question'] }}:</b> {{ answer['answer'] }}<br />
                        </template>
                    </template>
                </Card>
            </template>
        </Dialog>
        <Dialog v-model:visible='showAppointments' modal header='Storico appuntamenti' :style="{ width: '50rem' }"
                :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <DataTable :value='appointmentsData' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                       :rowHover='true'
                       responsiveLayout='scroll'
            >
                <template #empty>
                    Non ci sono elementi da mostrare.
                </template>
                <Column field='book_date' header='Data' :sortable='true' style='text-align: center'>
                    <template #body='slotProps'>
                        {{ localeTs2Date(slotProps.data['book_date']) }}
                    </template>
                </Column>
                <Column field='status' header='Status' :sortable='true' style='text-align: center' />
                <Column field='payment_status' header='Pagamento' :sortable='true' style='text-align: center' />
            </DataTable>

        </Dialog>
        <div class='grid'>
            <div class='col-12'>
                <div class='card'>
                    <div class='card-header'>
                        <h4>Elenco Pazienti di {{ psychoEmail }}</h4>
                    </div>
                    <template v-if='events && events.length'>
                        <Toolbar class='mb-3'>
                            <template #end>
                                <DataTable :value='events' class='p-datatable-sm'>
                                    <Column field='event_type'>
                                        <template #body='slotProps'>
                                            <Tag severity='success' value='Gratuito'
                                                 v-if='slotProps.data["event_type"] === "free"'></Tag>
                                            <Tag severity='warning' value='A Pagamento' v-else></Tag>
                                        </template>
                                    </Column>
                                    <Column field='id'>
                                        <template #body='slotProps'>
                                        <span @click='copyText(slotProps.data["url"])' class='pointer' title='Copia'><i
                                            class='pi pi-copy'></i></span>
                                        </template>
                                    </Column>
                                    <Column field='url'>
                                        <template #body='slotProps'>
                                            <a :href='slotProps.data["url"]' target='_blank'>{{ slotProps.data['url'] }}</a>
                                        </template>
                                    </Column>
                                </DataTable>
                            </template>
                        </Toolbar>
                    </template>
                    <DataTable :value='records' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                               :rowHover='true'
                               v-model:filters='filters' filterDisplay='menu' :loading='loading'
                               responsiveLayout='scroll'
                    >
                        <template #header>
                            <ListHeader
                                @clear='clearFilter()'
                                :hideNew='true'
                                v-model="filters['global'].value"
                                @add='$router.push({ path: gotoNew() })'
                                @refresh='refreshList()'
                                :loading='loading'
                            />
                        </template>
                        <template #empty>
                            Non ci sono elementi da mostrare.
                        </template>
                        <template #loading>
                            Loading ... Please wait.
                        </template>


                        <Column field='email' header='Email' :sortable='true' style='min-width:12rem'>
                            <template #body='slotProps'>
                                <span v-if='!slotProps.data["sparta_id"]'>{{ slotProps.data.email }}</span>
                                <router-link v-else :to="'/app/users/users/'+slotProps.data['sparta_id']" target='_blank'>
                                    {{ slotProps.data.email }}
                                </router-link>
                            </template>
                        </Column>
                        <Column field='first_appointment' header='Primo' :sortable='true' style='text-align: center' />
                        <Column field='paid' header='A pag.' :sortable='true' style='text-align: center'>
                            <template #body='slotProps'>
                                <badge @click='showAppData(slotProps.data["id"])' v-if="slotProps.data['paid']"
                                       :value="slotProps.data['paid']" severity='success' style='cursor: pointer'></badge>
                                <badge @click='showAppData(slotProps.data["id"])' v-else
                                       value="0" severity='warning' style='cursor: pointer'></badge>
                            </template>
                        </Column>
                        <Column field='next_app' header='Prossimo' :sortable='true' style='text-align: center' />

                        <Column field='id' header='Azioni'>
                            <template #body='slotProps'>
                                <font-awesome-icon
                                    class='text-blue-700 hover:text-blue-500 pointer ml-2'
                                    :style='{marginBottom: "5px", fontSize: "36px"}'
                                    title='Intervista'
                                    :icon="['fas', 'list-alt']"
                                    size='2x'
                                    @click='fetchInterview(slotProps.data["id"])'
                                />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ListHeader from '../../../components/4books/ListHeader.vue';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Psycho/Patients');
import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';

export default {
    data() {
        return {
            id: 0,
            filters: null,
            showAppointments: false,
            appointmentsData: [],
            showUserInterview: false,
            userInterview: [],
        };
    },
    components: { ListHeader },
    mixins: [Navigations, Notifications],
    computed: {
        ...mapGetters(['rows', 'loading']),
    },
    created() {
        this.initFilters();
    },
    emits: ['refresh'],
    methods: {
        ...mapActions(['fetchPatientAnswers']),
        refreshList(){
            this.$emit('refresh');
        },
        splitXEvent(rows) {
            const records = {};
            rows.forEach(row => {
                if (!records[row['event_id']]) {
                    records[row['event_id']] = {
                        submitted_at: row['submitted_at'],
                        landed_at: row['landed_at'],
                        answers: [],
                    };
                }
                records[row['event_id']]['answers'].push(row);
            });
            return records;
        },
        dismissAppData() {
            this.appointmentsData = [];
            this.showAppointments = false;
        },
        showAppData(uid) {
            this.appointmentsData = [];
            if (!this.rows) return;

            const userData = this.rows.filter(r => r['patient']['id'] === uid);
            if (userData.length === 0) return;
            let n = 0;
            if (!userData[0]['paid_appointments']){
                this.ShowWarning("Nessun dato")
                return;
            }
            this.appointmentsData = userData[0]['paid_appointments'].map(r => {
                return {
                    ...r,
                    id: n++,
                };
            });
            this.showAppointments = true;
        },

        fetchInterview(uid) {
            this.userInterview = [];
            this.fetchPatientAnswers({
                id: this.id,
                email: this.psychoEmail,
                patient_id: uid,
            }).then(res => {
                if (!res.length) {
                    this.ShowWarning('Nessuna risposta', 'Questo utente non ha interviste');
                    return;
                }
                this.userInterview = this.splitXEvent(res);
                this.showUserInterview = true;
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    props: {
        psychoEmail: {
            type: String,
            required: true,
        },
        events: {
            type: Array,
            default() {
                return [];
            },
        },
        records: {
            type: Array,
            default() {
                return [];
            },
        },

    },
};
</script>
