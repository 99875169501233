<template>
    <PatientsDatatable :psycho-email='psychoEmail' :records='records' :events='events' @refresh='refreshList'/>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Psycho/Patients');
const psychoStore = createNamespacedHelpers('Psycho/Psychologists');

import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import PatientsDatatable from '../shared/PatientsDatatable.vue';

export default {
    components: {
        PatientsDatatable,
    },
    data() {
        return {
            filters: null,
            psycho: null,
            showAppointments: false,
            appointmentsData: [],
            showUserInterview: false,
            userInterview: [],
            events: [],
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
        ...psychoStore.mapGetters(['getPsychoById']),
        psychoEmail() {
            if (this.psycho) return this.psycho.email;
            return '...';
        },
        records() {
            if (!this.rows) return [];
            return this.rows.map(row => {
                let paid = 0;
                const first_date = row['first_appointment'] ? this.localeTs2Date(row['first_appointment']['book_date']) : '-';
                let next_app = '-';
                if (row['paid_appointments']) {
                    paid = row['paid_appointments'].filter(r => r['status'] === 'active').length;
                    const now = new Date().getTime();
                    let i = 0;
                    while (i < row['paid_appointments'].length) {
                        const app = row['paid_appointments'][i];
                        i++;
                        if (app['status'] !== 'active') {
                            continue;
                        }
                        if (next_app === '-') {
                            next_app = app['book_date'];
                        }
                        const d = new Date(app['book_date']).getTime();
                        if (d > now) {
                            next_app = app['book_date'];
                            break;
                        }
                    }
                }
                return {
                    id: row['patient']['id'],
                    sparta_id: row['patient']['sparta_id'],
                    paid: paid,
                    first_appointment: first_date,
                    email: row['patient']['email'],
                    next_app: next_app !== '-' ? this.localeTs2Date(next_app) : next_app,
                };
            });
        },
    },
    mounted() {
        this.refreshList();
        this.refreshEvents(this.id);
    },
    methods: {
        ...mapActions(['fetchPatientList', 'fetchPatientAnswers', 'fetchCalendlyEvents']),
        ...psychoStore.mapActions(['fetchList']),
        async refreshEvents(id) {
            this.fetchCalendlyEvents({
                id: id,
            }).then(res => {
                this.events = res;
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        async fetchPsycho() {
            this.psycho = null;
            let present = this.getPsychoById(this.id);
            if (present && present.id) {
                this.psycho = present;
                return;
            }

            await this.fetchList();
            present = this.getPsychoById(this.id);
            if (present && present.id) {
                this.psycho = present;
                return;
            }
            console.log('STILL NOT FOUND');
        },
        async refreshList() {
            await this.fetchPsycho();
            if (!this.psycho || this.psycho.id !== this.id) {
                this.ShowError('Errore', 'Impossibile recuperare i dati dello psicologo');
                return;
            }
            this.fetchPatientList({
                id: this.id,
                email: this.psycho.email,
            }).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Notifications,
        Navigations,
    ],
    props: {
        id: {
            default: 0,
            type: Number,
        },
    },
    watch: {
        id(n, o) {
            if (n !== o) {
                this.refreshList();
                this.refreshEvents(n);
            }
        },
    },
};
</script>
